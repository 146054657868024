'use client'

import { useEffect, useState } from 'react'

import type { ChangeEvent } from 'react'
import { Flex } from '@/components/flex'
import { Button } from '../button'
import { CloseIcon } from '../icons/svg'
import { Select } from '../select'
import { Text } from '../text'
import { useGeoLocation } from './use-geo-location'
import { Link } from '../link/link'
import type { Store } from '../header/components/language-switcher'
import { CookieKeys, setCookie } from '@/common/utils/cookie-utils'
import { Modal } from '../modal/modal'
import { useDisclosure } from '@/common/hooks/use-disclosure'

export const GB_GEO_LOCATION = 'gb-geo-location'
const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000

type StoreNavigatorProps = {
  stores: Store[]
  currentStore?: Store
}

export const StoreNavigator = ({
  stores,
  currentStore,
}: StoreNavigatorProps) => {
  const [selectedStore, setSelectedStore] = useState(currentStore)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleOnModalClose = () => {
    localStorage.setItem(GB_GEO_LOCATION, String(Date.now() + ONE_DAY_IN_MS))
    onClose()
  }

  const { geoLocationData, recommendedStoreCode } = useGeoLocation({
    stores,
    onNavigatorOpen: onOpen,
  })

  const handleSelectStore = (e: ChangeEvent<HTMLSelectElement>) => {
    const storeCode = e.target.value
    const store = stores.find((store) => store.storeCode === storeCode)

    store && setSelectedStore(store)
  }

  useEffect(() => {
    if (!recommendedStoreCode) {
      return
    }

    const recommendedStore = stores.find(
      (store) => store.storeCode === recommendedStoreCode,
    )

    recommendedStore && setSelectedStore(recommendedStore)
  }, [stores, recommendedStoreCode])

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="w-11/12 md:w-[420px]">
      <Flex className="modal-title justify-center mb-5">
        <Text size="lg" className="text-md">
          {geoLocationData?.description}
        </Text>
      </Flex>
      <Flex className="modal-content justify-center">
        <div className="w-full">
          <Select
            onChange={handleSelectStore}
            value={selectedStore?.storeCode}
            className="flex-grow text-sm w-full"
          >
            {stores?.map((store) => {
              return (
                store?.storeCode !== currentStore?.storeCode && (
                  <option key={store.storeCode} value={store.storeCode}>
                    {store.label}
                  </option>
                )
              )
            })}
          </Select>
          <Link
            keepUrl
            href={selectedStore ? selectedStore?.url : ''}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()

              if (selectedStore) {
                setCookie(CookieKeys.STORE_CODE, selectedStore.storeCode)
                history.pushState({}, '', selectedStore.url)
                window.location.reload()
              }
            }}
          >
            <Button
              disabled={!selectedStore?.url}
              size="base"
              variant="secondary"
              className="w-full mt-3"
            >
              <span className="text-white font-bold">
                {geoLocationData?.redirect_button_title}
              </span>
            </Button>
          </Link>
        </div>
      </Flex>
      {geoLocationData?.stay_button_title && (
        <footer className="flex justify-center pt-7">
          <button onClick={onClose} className="underline">
            {geoLocationData?.stay_button_title}
          </button>
        </footer>
      )}
      <Link
        keepUrl
        href="/"
        className="absolute top-3 right-5"
        onClick={(e) => {
          e.preventDefault()
          handleOnModalClose()
        }}
      >
        <CloseIcon />
      </Link>
    </Modal>
  )
}
